//
// Form Select
//

.display-flow {
  display: flow !important;
}

.display-flow label {
  padding-top: 9px;
}

.box-multi-select {
  float: right;
  width: 67%;
}

.form-multi-select {
  box-shadow: none !important;
  display: block;
  width: 100%;
  height: 34px;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 0.925rem;
  font-weight: 500;
  /* line-height: 1.5; */
  color: #181c32;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #e4e6ef;
  border-radius: 0.475rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;

  // Solid style
  &.form-multi-select-solid {
    @include placeholder($input-solid-placeholder-color);
    background-color: $input-solid-bg;
    border-color: $input-solid-bg;
    color: $input-solid-color;
    transition: $transition-input;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $input-solid-bg-focus;
      border-color: $input-solid-bg-focus !important;
      color: $input-solid-color;
      transition: $transition-input;
    }
  }

  // White style
  &.form-select-white {
    @include placeholder($input-placeholder-color);
    background-color: $body-bg;
    border-color: $body-bg;
    color: $input-color;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $body-bg;
      border-color: $body-bg !important;
      color: $input-color;
    }
  }
}

.dropdown-heading {
  height: 32px !important;
}

.dropdown-heading svg {
  scale: 0.8 !important;
  color: #7e8399 !important;
}

.dropdown-container {
  position: inherit !important;
  outline: inherit !important;
  background-color: none !important;
  border: none !important;
  border-radius: none !important;

  &:focus-within {
    box-shadow: none !important;
  }
}

.gray {
  color: inherit !important;
}

.dropdown-content {
  top: 95% !important;
  padding-top: 0 !important;
  right: 35px !important;
}